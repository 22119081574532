import React, { Component } from 'react'
import LoginBox from './LoginBox';
import Setting from '../Setting'

export default class LoginFrame extends Component {

    constructor(props) {
        super(props)
        this.state = {
            chinese : true,
            clientId : '',
            businessReturnUrl : '',
        }
        this.baseServerPath = Setting('baseServerPath')
        this.env = Setting('env')
    }

    componentWillMount() {
        let search = this.props.location.search.replace('?', '');
        let queryArray = search.split('&');
        if (queryArray.length === 0) {
            return
        }
        for (let i=0; i<queryArray.length; i++) {
            let paramArray = queryArray[i].split('=');
            if (paramArray.length < 2) {
                continue
            }
            switch (paramArray[0]) {
                case 'ReturnUrl':
                    this.setState({businessReturnUrl: unescape(paramArray[1])})
                    break
                case 'lang':
                    this.setState({chinese: paramArray[1] === 'zh_CN'})
                    break
                case 'clientId':
                    this.setState({clientId: paramArray[1]})
                    break
                default:
                    break
            }
        }
    }

    render() {
        let loginFrameStyle = {
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        }
        
        return (
            <div style = {loginFrameStyle}>
                <LoginBox
                    clientId = {this.state.clientId}
                    businessReturnUrl = {this.state.businessReturnUrl}
                    chinese = {this.state.chinese}
                    baseServerPath={this.baseServerPath}
                    env={this.env}
                ></LoginBox>
            </div>
        )
    }
}
