const active = 'prod';

const TEST_PROPERTIES = {
  env: 'test',
  baseServerPath: 'http://esuite-test.jd.com/passport/api',
  janusHost: 'http://janus-api-test.jd.com',
  janusAppId: '448114541067374592',
  janusStage: 'PRE',
};

const PRE_PROPERTIES = {
  env: 'pre',
  baseServerPath: 'https://esuite-pre.jd.com/passport/api',
  janusHost: 'https://janus-api-pre.jd.com',
  janusAppId: '448114541067374592',
  janusStage: 'PRE',
};

const PROD_PROPERTIES = {
  env: 'prod',
  baseServerPath: 'https://esuite.jd.com/passport/api',
  janusHost: 'https://janus-api.jd.com',
  janusAppId: '448114541067374592',
  janusStage: 'PROD',
};

const LOCAL_PROPERTIES = {
  env: 'local',
  baseServerPath: 'http://esuite-test.jd.com/api',
};

export default function getBaseServerPath(name) {
  switch (active) {
    case 'test':
      return TEST_PROPERTIES[name];
    case 'pre':
      return PRE_PROPERTIES[name];
    case 'prod':
      return PROD_PROPERTIES[name];
    case 'local':
      return LOCAL_PROPERTIES[name];
    default:
      return 'unknown properties!';
  }
}
