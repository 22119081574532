import React, { PureComponent } from 'react';
import LoginPage from './components/LoginPage';
import LoginFrame from './components/LoginFrame';
import SelectTeam from './components/SelectTeam';
import ComfirmPage from './components/ConfimPage';
import { BrowserRouter, Route } from 'react-router-dom';

export default class App extends PureComponent {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Route exact path="/passport" component={LoginPage}></Route>
          <Route path="/passport/frame" component={LoginFrame}></Route>
          <Route path="/passport/select/teams/" component={SelectTeam}></Route>
          <Route path="/passport/confirm/scan" component={ComfirmPage}></Route>
        </BrowserRouter>
      </div>
    );
  }
}
