import React, { PureComponent } from 'react'
import cookie from 'react-cookies';

export default class SelectTeam extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            selectValue : '',
            returnUrl : '',
            userTeamInfoList : []
        }
    }

    componentWillMount() {
        let userTeamName = 'focus-team-user'
        let strList = cookie.load(userTeamName);
        if (strList) {
            this.setState({userTeamInfoList: strList}, 
                () => this.setState({selectValue : this.state.userTeamInfoList[0].teamId})
            )
        } else {
            console.error('获取用户团队信息失败')
        }

        let returnUrlName = 'focus-return-url'
        let returnUrl = cookie.load(returnUrlName);
        if (returnUrl) {
            this.setState({returnUrl: returnUrl})
        } else {
            console.error('获取回调url失败')
        }
    }


    confirmTeam = () => {
        if (this.state.selectValue) {
            console.log(this.state.selectValue)
        }
    }

    onSelectTeam = event => {
        this.setState({selectValue : event.target.value})
    }

    getDefaultValue = () => {
        if (this.state.userTeamInfoList.length > 0) {
            return this.state.userTeamInfoList[0].teamId
        }
        return ''
    }

    render() {
        return (
            <div>
                <select defaultValue = {this.getDefaultValue} onChange={this.onSelectTeam}>
                    {
                        this.state.userTeamInfoList.map((userTeamInfo, index) => {
                            return (
                                <option key = {index} value={userTeamInfo.teamId}>
                                    {userTeamInfo.emplRealName}
                                </option>
                            )
                        })
                    }
                </select>
                <button type = 'button' onClick = {this.confirmTeam}>确认</button>
            </div>
        )
    }
}
